import React, {Fragment} from 'react';
import useController from "../../framework/alto/data/useController";
import computerScienceArticlesController from "../../controllers/computer_science_articles_controller";
import ArticleToolbar from "../../components/articles/article-toolbar";
import View from "../../framework/alto/ui/view";
import ArticleHeadline from "../../components/articles/article-headline";
import ImageView from "../../framework/alto/ui/image_view";
import css from "./linked-list.module.css";
import LinkedListDiagram1 from "../../images/linkedlist-2.1.png";
import LinkedListDiagram2 from "../../images/linkedlist-2.2.png";
import LinkedListDiagram3 from "../../images/linkedlist-2.3.png";
import LinkedListDiagram4 from "../../images/linkedlist-2.4.png";
import LinkedListDiagram5 from "../../images/linkedlist-2.5.png";
import LinkedListDiagram6 from "../../images/linkedlist-2.6.png";
import LinkedListDiagram7 from "../../images/linkedlist-2.7.png";
import LinkedListDiagram8 from "../../images/linkedlist-2.8.png";
import LinkedListDiagram9 from "../../images/linkedlist-2.9.png";
import LinkedListDiagram10 from "../../images/linkedlist-2.10.png";

let LinkedListArticle = () => {
    let [data] = useController(computerScienceArticlesController);

    return (
        <Fragment>
            <ArticleToolbar data={data.collection[1]}/>
            <View className={css.scroll}>
                <ArticleHeadline data={data.collection[1]}/>
                <section className={css.documentView}>
                    <View className={css.columnOne}>
                        <span className={css.bold}>Linked List </span>
                        <label>comes in three basic varieties: <span className={css.italic}>singly, doubly,</span> and
                            <span className={css.italic}> circular.</span>
                            <br/> <br/>
                            <span className={css.bold}>Singly </span>linked list are linear lists where each element
                            ‘points’ to the element that follows it in the list.  The first element in a singly linked
                            list is known as the <span className={css.italic}>head. </span>While the last element in
                            the list is known as the <span className={css.italic}>tail.</span>
                            <br/> <br/>
                            With the exception of the tail.  All elements point to the next element in the list.
                            While the tail has a null link.  A visual of a singly linked list is shown below in figure 2.1
                        </label>
                        <ImageView src={LinkedListDiagram1} className={css.linkedListDiagram}/>
                        <label>
                            Since an element in a singly linked list only points to the next element, the list can only be
                            traversed in the forward direction.  Because of this limitation, a complete traversal of the
                            list must start with the first element.  In other words, when traversing the list in figure 2.1,
                            we need to start at the head.  If we do not, all elements in the list will not be traversed.
                        </label>
                    </View>
                    <View className={css.columnTwo}>
                        <label>
                            <span className={css.bold}>Doubly </span>linked list forgoes the prior mentioned limitation
                            that a singly linked list has.
                            <br/><br/>
                            Shown below in figure 2.2, each element in a doubly linked list points to the
                            <span className={css.italic}> next</span> and <span className={css.italic}>previous </span>
                            elements. However, the head element will not have a link to a previous element and the tail
                            will not have a link to a next element.
                        </label>
                        <ImageView src={LinkedListDiagram2} className={css.linkedListDiagram}/>
                    </View>
                </section>

                <section className={css.documentView}>
                    <View className={css.columnOne}>
                        <span className={css.bold}>Circular </span>
                        <label> linked lists are singly in structure or doubly in structure and have no head nor tail.
                            Meaning, regardless of a singly or doubly linked list, each element will point to another
                            element.
                            <br/><br/>
                            In-regards to a circular linked list that is singly in structure.  Next links will always
                            have a value and will never be null.  See figure 2.3.
                        </label>
                        <ImageView src={LinkedListDiagram3} className={css.linkedListDiagram}/>
                        <label>
                            In the case of a circular doubly linked list, next and previous will always have a value
                            and will never be null.    See figure 2.4.
                        </label>
                        <ImageView src={LinkedListDiagram4} className={css.linkedListDiagram}/>
                    </View>
                    <View className={css.columnTwo}>
                        <label>
                            The challenge with circular linked lists is how to avoid cycling through the list infinitely.
                            To overcome this challenge, when traversing a circular list, we will need to track where we
                            start our traversal.
                        </label>
                    </View>
                </section>

                <section className={css.documentView}>
                    <View className={css.columnOne}>
                        <span className={css.boldLarge}>Linked List <br/></span>
                        <span className={css.bold}>in-Action with Alto.js <br/> <br/></span>
                        <label>Our data structure to describe an element in a linked list will be a javascript object
                            literal.  Specifically, our element for a singly linked list will have two properties: data,
                            and next.
                            <br/><br/>
                            Once we have a LinkedList generated, we can ‘walk’ the list by storing the value of the
                            current element to a variable and reassigning the variable with the returned element from
                            <span className={css.code}> element.next.</span>  This pattern is repeated until all of the
                            elements are touched and the tail element is found.  See figure 2.5.
                            <br/><br/>
                            Walking the linked list in such a fashion is not of much use. Let us extend the
                            functionality of our <span className={css.code}>LinkedListAlgorithms</span> class.
                            Ultimately we will want an api to search, insert (before/after), and delete (all/one)
                            elements. Let us start by adding a way to find an element within a linked list.
                        </label>
                    </View>
                    <View className={css.columnTwo}>
                        <ImageView src={LinkedListDiagram5} className={css.linkedListDiagram}/>
                    </View>
                </section>

                <section className={css.documentView}>
                    <View className={css.columnOne}>
                        <label>Since we are working on an api for singly linked list.  We will prefix our find method
                            with the letter ’s’.  Going forward all methods in our linked list api will follow this
                            naming pattern.
                            <br/><br/>
                            <span className={css.code}>sFind</span> is a straight forward operation.  Given a value to
                            lookup and the head of a linked list, return the matched element.   The pitfall here is
                            traversing the entire linked list without finding the provided value.  See figure 2.6.
                        </label>
                        <ImageView src={LinkedListDiagram6} className={css.linkedListDiagram}/>
                    </View>
                    <View className={css.columnTwo}>
                        <span className={css.code}>sInsertBefore</span> is more involved than
                        <span className={css.code}>sFind</span>: given a value to insert, a lookup to insert the new
                        value before, and the head of a linked list return the updated linked list.   As with our find
                        method, the pitfall here is traversing the entire linked list without finding the provided
                        lookup.  Additionally, we need to handle the edge-case of inserting before the head.  See figure 2.7.
                        <ImageView src={LinkedListDiagram7} className={css.linkedListDiagram}/>
                    </View>
                </section>

                <section className={css.documentView}>
                    <View className={css.columnOne}>
                        <label>
                            <span className={css.code}>sInsertAfter</span>: given a value to insert, a lookup to insert
                            the new value after, and the head of a linked list, return the updated linked list. As we
                            traverse through the list, we pass back the current element as head.  There are two logical
                            paths to watch out for and one edge case.
                            <br/><br/>
                            The first is when the data matches our lookup and we are not at a tail element, meaning
                            head has a non-null next value. We will create a new element, assign its next value to the
                            current head.next then assign the element to the next value of our current head.
                            <br/><br/>
                            The second case if when our data matches the lookup and it is our tail element.  Here we
                            create a new element, assign null to the new elements next value, and replace the current
                            head.next to the new element.
                            <br/><br/>
                            And last we handle the case of reaching the tail but not finding a matching lookup.
                        </label>
                    </View>
                    <View className={css.columnTwo}>
                        <ImageView src={LinkedListDiagram8} className={css.linkedListDiagram}/>
                    </View>
                </section>

                <section className={css.documentView}>
                    <View className={css.columnOne}>
                        <label>
                            <span className={css.code}>sDelete</span>: given a lookup to match, and the head of a
                            linked list, return the updated linked list minus our match to the lookup.
                            <br/><br/>
                            Before traversing the linked list, create a new value for head as element to delete, check
                            if the element to delete matches our lookup.  When there is a match, meaning we want to
                            delete the head of the list, move the head to the next node, null out the element to delete
                            and return the updated head.
                            <br/><br/>
                            When the element to delete is not the head of the list, check to see if we reached the tail
                            element with no match.  If so, throw a no match found error.  If we are not at the tail,
                            and our current element to delete next value matches our lookup, move the head.next to the
                            next.next element, null out the element to delete, and return the updated head.
                            <ImageView src={LinkedListDiagram9} className={css.linkedListDiagram}/>
                        </label>
                    </View>
                    <View className={css.columnTwo}>
                        <label>
                            <span className={css.code}>sDeleteList</span>: given the head of a linked list, delete the
                            list.
                            <br/><br/>
                            Dealing with deletion of a list isn’t as straight forward as we may think.  The natural
                            process is to use a single reference while traversing the list and remove items we go.
                            The challenge here is, “Do we move head first or remove the element first?”  Both options
                            will not work.
                            <br/><br/>
                            We cannot move the value first, dletion will be impossible since we no longer have the
                            current head value to delete. And we cannot delete the element first, moving to the next
                            head will be impossible since we no longer have an element with a next value.  The solution
                            is to use two values for head: one for tracking head and another for the element to delete.
                        </label>
                        <ImageView src={LinkedListDiagram10} className={css.linkedListDiagram}/>
                    </View>
                </section>

            </View>
        </Fragment>
    )

};

export default LinkedListArticle;