import Controller from "../framework/alto/data/controller";
import mergesortHero from "../images/mergesort-hero.png";
import linkedListHero from "../images/linkedlist-hero.png";
import treeHero from "../images/tree-hero.png";

let computerScienceArticlesController = Controller({

    collection: [{
        title: "Trees",
        date: "01/17/2019",
        author: "Chad Eubanks",
        url: "/articles/trees",
        hero: treeHero,
        heroAlt: 'tree hero'
    }, {
        title: "Linked List",
        date: "01/10/2019",
        author: "Chad Eubanks",
        url: "/articles/linked-list",
        hero: linkedListHero,
        heroAlt: 'linkedlist hero'
    }, {
        title: "Merge Sort",
        date: "12/20/2018",
        author: "Chad Eubanks",
        url: "/articles/merge-sort",
        hero: mergesortHero,
        heroAlt: 'mergesort hero'
    }]

});

export default computerScienceArticlesController;