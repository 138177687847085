import Datastore from "./datastore";
import CoreObject from "../foundation/core_object";

let Controller = function (data) {
    data = CoreObject.create(data);

    let guid = data.guid;

    if (Datastore.get([guid])) {
        throw new Error(`Controller with id: ${guid} already exists within application datastore`)
    }

    let controller = {
        data,
        reducer: (state, payload) => {
            return Object.assign({}, state, payload);
        },
        setState(action) {
            this.data = this.reducer(this.data, action);
            this.setters.forEach(setter => setter(this.data));
        },
        setters: []
    };

    controller.setState = controller.setState.bind(controller);

    Datastore.set([guid], controller);
    return controller;
};

export default Controller;