import React, {Fragment} from 'react';
import styles from './article-headline.module.css';

const ArticleHeadline = ({
                             data
                         }) => {

    return (

        <Fragment>
            <div className={styles.documentFrame}>
                <div className={styles.title}><span>{data.title}</span></div>
                <div className={styles.subtitle}><span>{data.subTitle}</span></div>
                <picture className={styles.heroPicture}>
                    <source media="(min-width: 769px)" srcSet={data.hero}/>
                    <img className={styles.hero} src={data.hero} alt={data.heroAlt}/>
                </picture>
            </div>
            <div className={styles.distortedBackground}/>
        </Fragment>
    )

};

export default ArticleHeadline;