import React from 'react';
import css from './article-toolbar.module.css';
import Link from "gatsby-link";
import navigation from "../../images/navigation.png";
import ImageView from "../../framework/alto/ui/image_view";

const ArticleToolbar = ({
                            data
                        }) => (

    <div className={css.toolbar}>
        <Link to="/" className={css.linkHome}>
            <ImageView className={css.navigation} src={navigation} alt={'navigation'}/>
        </Link>
        <div className={css.title}><span>{`Article Date: ${data.date}`}</span></div>
    </div>
);

export default ArticleToolbar;